module.exports = {
  "pageView": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": "string",
      "groupKey": {
        "type": "string",
        "isOptional": true
      },
      "via": {
        "type": "string",
        "isOptional": true
      },
      "onboardingKey": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "getting-started-ui",
    "version": "3"
  },
  "tabView": {
    "name": "tab view",
    "class": "view",
    "properties": {
      "groupKey": {
        "type": "string",
        "isOptional": true
      },
      "onboardingKey": {
        "type": "string",
        "isOptional": true
      },
      "selectedView": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "edition": {
        "type": "string",
        "isOptional": true
      },
      "via": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "getting-started-ui",
    "version": "3"
  },
  "sectionView": {
    "name": "section view",
    "class": "view",
    "properties": {
      "section": {
        "type": "string"
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "additionalValues": {
        "type": "array",
        "isOptional": true
      },
      "groupKey": {
        "type": "string",
        "isOptional": true
      },
      "onboardingKey": {
        "type": "string",
        "isOptional": true
      },
      "selectedView": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "edition": {
        "type": "string",
        "isOptional": true
      },
      "via": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "getting-started-ui",
    "version": "3"
  },
  "taskInteraction": {
    "name": "task interaction",
    "class": "interaction",
    "properties": {
      "dependentTask": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string"
      },
      "handholdingEnabled": {
        "type": "boolean",
        "isOptional": true
      },
      "taskName": {
        "type": "string",
        "isOptional": true
      },
      "taskState": {
        "type": "string",
        "isOptional": true
      },
      "groupKey": {
        "type": "string",
        "isOptional": true
      },
      "onboardingKey": {
        "type": "string",
        "isOptional": true
      },
      "selectedView": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "edition": {
        "type": "string",
        "isOptional": true
      },
      "via": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "getting-started-ui",
    "version": "3"
  },
  "userInteraction": {
    "name": "user interaction",
    "class": "interaction",
    "properties": {
      "context": {
        "type": "string",
        "isOptional": true
      },
      "tier": {
        "type": [
          "free",
          "starter",
          "pro",
          "enterprise",
          "common"
        ],
        "isOptional": true
      },
      "action": {
        "type": "string"
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "groupKey": {
        "type": "string",
        "isOptional": true
      },
      "onboardingKey": {
        "type": "string",
        "isOptional": true
      },
      "selectedView": {
        "type": "string",
        "isOptional": true
      },
      "edition": {
        "type": "string",
        "isOptional": true
      },
      "via": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "getting-started-ui",
    "version": "3"
  },
  "exploreCardInteraction": {
    "name": "explore card interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      },
      "name": {
        "type": "string"
      },
      "hub": {
        "type": [
          "crm",
          "sales",
          "marketing",
          "service",
          "cms",
          "common",
          "ops"
        ]
      },
      "tier": {
        "type": [
          "free",
          "starter",
          "pro",
          "enterprise",
          "common"
        ]
      },
      "experience": {
        "type": [
          "none",
          "crm-experience",
          "hs-experience",
          "common"
        ]
      },
      "cleanedUrl": {
        "type": "string",
        "isOptional": true
      },
      "groupKey": {
        "type": "string",
        "isOptional": true
      },
      "onboardingKey": {
        "type": "string",
        "isOptional": true
      },
      "selectedView": {
        "type": "string",
        "isOptional": true
      },
      "tab": {
        "type": "string",
        "isOptional": true
      },
      "edition": {
        "type": "string",
        "isOptional": true
      },
      "via": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "getting-started-ui",
    "version": "3"
  },
  "blogQuickCreateModalOpened": {
    "name": "blog quick create modal action",
    "class": "interaction",
    "namespace": "content-components"
  },
  "blogQuickCreateModalClickedCreate": {
    "name": "blog quick create modal action",
    "class": "interaction",
    "namespace": "content-components"
  },
  "audienceAccessInteraction": {
    "name": "audience access interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "click-radio-all",
          "click-radio-password",
          "click-radio-sso",
          "click-radio-specificContacts",
          "click-radio-ssoWithSpecificContacts",
          "show-password-help",
          "show-sso-help",
          "show-specificContacts-help",
          "show-ssoWithSpecificContacts-help",
          "change-lists",
          "click-kb-article",
          "show-sso-disabled-tooltip",
          "show-registration-disabled-tooltip"
        ]
      }
    },
    "namespace": "content-components"
  },
  "partnerMatchingAppInteraction": {
    "name": "partner matching app interaction",
    "namespace": "partner-matching-app",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      },
      "app": {
        "type": "string"
      }
    }
  },
  "colorImportInteraction": {
    "name": "color import modal interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "submit-url",
          "website-colors-retrieved",
          "toggle-color-selection",
          "import-colors"
        ]
      }
    },
    "namespace": "content-components"
  },
  "setupCustomDomainModalInteraction": {
    "name": "setup custom domain modal interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "view",
          "clicked-add-custom-domain-button",
          "clicked-close-modal",
          "clicked-remind-me-tomorrow-button",
          "clicked-do-not-show-me-again-button"
        ]
      },
      "screen": [
        "detail"
      ]
    },
    "namespace": "content-components"
  },
  "postPublishContentRemixModalInteraction": {
    "name": "ContentComponents interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "view-modal",
          "close-modal-temporary",
          "close-modal-permanent",
          "click-remix"
        ]
      },
      "contentId": {
        "type": "number"
      }
    },
    "namespace": "content-components"
  },
  "ContentComponentsInteraction": {
    "name": "ContentComponents interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "custom-fonts-upload-modal-opened",
          "custom-fonts-upload-modal-closed",
          "custom-fonts-create-font",
          "custom-fonts-edit-font",
          "custom-fonts-delete-font"
        ]
      }
    },
    "namespace": "content-components"
  },
  "contentCreateAutoTranslationActionUsage": {
    "name": "content-create-autotranslation-action",
    "class": "usage",
    "properties": {
      "action": [
        "create-multi-language-variation",
        "direct-translate"
      ],
      "status": [
        "success",
        "fail"
      ],
      "screen": {
        "type": "string"
      },
      "subscreen": {
        "type": "string"
      },
      "pageType": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "content-components"
  },
  "contentCreateAutoTranslationActionActivation": {
    "name": "content-create-autotranslation-action",
    "class": "activation",
    "properties": {
      "screen": {
        "type": "string"
      },
      "subscreen": {
        "type": "string"
      },
      "pageType": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "content-components"
  },
  "contentCreateAutoTranslationActionInteraction": {
    "name": "content-create-autotranslation-action",
    "class": "interaction",
    "properties": {
      "action": [
        "clicked-submit-create-multi-language-variation",
        "clicked-ai-translate-toggle"
      ],
      "screen": {
        "type": "string"
      },
      "subscreen": {
        "type": "string"
      },
      "pageType": {
        "type": "string",
        "isOptional": true
      },
      "isAutoTranslateEnabled": {
        "type": "boolean",
        "isOptional": true
      }
    },
    "namespace": "content-components"
  }
};