import { INTEGRATIONS_HIDE_SIDE_PANEL, INTEGRATIONS_SET_SELECTED_INTEGRATION, INTEGRATIONS_SHOW_SIDE_PANEL } from './ActionTypes';
export function showIntegrationsSidePanel() {
  return {
    type: INTEGRATIONS_SHOW_SIDE_PANEL
  };
}
export function hideIntegrationsSidePanel() {
  return {
    type: INTEGRATIONS_HIDE_SIDE_PANEL
  };
}
export function setSelectedIntegration(slug) {
  return {
    type: INTEGRATIONS_SET_SELECTED_INTEGRATION,
    payload: {
      slug
    }
  };
}