import { useDispatch, useSelector } from 'react-redux';
import { hideIntegrationsSidePanel, setSelectedIntegration, showIntegrationsSidePanel } from '../actions/IntegrationActions';
import { useCallback } from 'react';
export function useIntegrationsSidePanel() {
  const dispatch = useDispatch();
  const isSidePanelOpen = useSelector(state => state.integrations.isSidePanelOpen);
  const showSidePanel = useCallback(() => {
    dispatch(showIntegrationsSidePanel());
  }, [dispatch]);
  const hideSidePanel = useCallback(() => {
    dispatch(hideIntegrationsSidePanel());
  }, [dispatch]);
  return {
    isSidePanelOpen,
    showSidePanel,
    hideSidePanel
  };
}
export function useSelectedIntegration() {
  const dispatch = useDispatch();
  return [useSelector(state => state.integrations.selectedOfferingSlug), useCallback(slug => dispatch(setSelectedIntegration(slug)), [dispatch])];
}