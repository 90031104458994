import { createStack } from 'hub-http';
import promiseClient from 'hub-http/adapters/promiseClient';
import * as coreMiddlewares from 'hub-http/middlewares/core';
import appAuthStack from 'hub-http/stacks/hubapi';
import noAuthStack from 'hub-http/stacks/noAuthWithCredentialsHubapi';
export const marketplaceSearchAppClient = promiseClient(createStack(coreMiddlewares.base('marketplace-search/v1/'), appAuthStack));
export const marketplaceSearchPublicClient = promiseClient(createStack(coreMiddlewares.base('marketplace-search/public/v1/'), noAuthStack));
export function getMarketplaceSearchClient(appContext) {
  switch (appContext) {
    case 'public':
      return marketplaceSearchPublicClient;
    default:
      return marketplaceSearchAppClient;
  }
}