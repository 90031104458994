import { TREATMENTS_SCHEMA } from '../constants/experiments';

/**
 * Checks if an experiment override is set in local storage and returns the corresponding group if it matches
 * one of the allowed groups for the specified experiment.
 *
 * @template T - The type of the experiment key, which must be a valid `TreatmentKey`.
 * @param {T} experimentKey - The unique identifier of the experiment to check for an override.
 *                            This key should match one defined in `TREATMENTS_SCHEMA`.
 * @returns {GroupType<T> | undefined} - Returns the override group (e.g., 'control', 'variant', 'variant_a')
 *                                       if set in local storage and valid for the experiment; otherwise, returns `undefined`.
 *
 * @example
 * // Assuming `TREATMENTS_SCHEMA['gpro-0004']` has groups 'control' and 'variant':
 * localStorage.setItem('growth-pro-gpro-0004Override', 'control');
 * const group = checkExperimentOverride('gpro-0004'); // Returns 'control'
 *
 * @throws Will return `undefined` if an error occurs while accessing local storage.
 */
export const checkExperimentOverride = experimentKey => {
  const overrideKey = `growth-pro-${experimentKey}Override`;
  try {
    const overrideValue = localStorage.getItem(overrideKey);
    if (!overrideValue) {
      return undefined;
    }
    const allowedGroups = TREATMENTS_SCHEMA[experimentKey].parameters['group'];
    const isAllowedGroup = value => allowedGroups.includes(value);
    if (isAllowedGroup(overrideValue)) {
      return overrideValue;
    }
  } catch (error) {
    // Return undefined if there's an error accessing localStorage
    return undefined;
  }
  return undefined;
};