import { useCallback, useEffect, useState } from 'react';
import { useEmbeddedContext } from './embeddedContext';
const USE_FULLSCREEN = 'USE_FULLSCREEN';
export function useFullscreenSender() {
  const {
    sendMessage
  } = useEmbeddedContext();
  return useCallback(fullscreen => sendMessage(USE_FULLSCREEN, {
    fullscreen
  }), [sendMessage]);
}
export function useFullscreenReceiver() {
  const [fullscreen, setFullscreen] = useState(false);
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  useEffect(() => {
    const handleResize = () => setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return {
    fullscreen,
    screenSize,
    receiver: {
      type: USE_FULLSCREEN,
      onMessageReceived: payload => {
        if (typeof payload.fullscreen !== 'boolean') {
          const error = `Error validating 'fullscreen' type in ${USE_FULLSCREEN} message. Received ${typeof payload.fullscreen}.`;
          console.error(error);
          return;
        }
        setFullscreen(payload.fullscreen);
      }
    }
  };
}