import { registerMutation, useMutation } from 'data-fetching-client';
import { assignTaskToUser } from '../../api/assignedTasksApi';
export const ASSIGN_TASK_MUTATION = registerMutation({
  fieldName: 'assignTask',
  fetcher: ({
    taskId,
    userId
  }) => assignTaskToUser({
    taskId,
    userId
  })
});
export const useAssignTask = options => {
  const [assignTask, {
    loading,
    error
  }] = useMutation(ASSIGN_TASK_MUTATION, Object.assign({}, options));
  return {
    assignTask,
    loading,
    error
  };
};