import { useQuery } from 'data-fetching-client';
import { QUERY_RECENT_POST_PURCHASE } from '../queries/recentPostPurchaseApi';
import { getPortalId } from '../utils/getPortalId';
import { isLastPurchaseSalesHubProfessional } from '../utils/isLastPurchaseSalesHubProfessional';
import { isGroupTypeValue } from '../utils/experiments';
import { checkExperimentOverride } from '../utils/checkExperimentOverride';
export const useIsGPRO0003 = (variant = '', portalId) => {
  const override = checkExperimentOverride('gpro-0003');
  const {
    data,
    loading,
    error
  } = useQuery(QUERY_RECENT_POST_PURCHASE, {
    variables: {
      portalId: portalId || getPortalId()
    }
  });
  if (override === 'control') {
    return {
      variant: override,
      loading: false,
      isGPRO0003: false,
      isGPRO0003Audience: false,
      error: undefined
    };
  }
  if (override === 'variation') {
    return {
      variant: override,
      loading: false,
      isGPRO0003: true,
      isGPRO0003Audience: true,
      error: undefined
    };
  }
  if (loading) {
    return {
      variant: undefined,
      loading: true,
      isGPRO0003: undefined,
      error: undefined,
      isGPRO0003Audience: undefined
    };
  }
  if (error || !isGroupTypeValue(variant, 'gpro-0003')) {
    return {
      variant: undefined,
      loading: false,
      isGPRO0003: undefined,
      error: error || new Error('Invalid variant'),
      isGPRO0003Audience: undefined
    };
  }
  const found = isLastPurchaseSalesHubProfessional(data === null || data === void 0 ? void 0 : data.recentPostPurchase);
  return {
    variant,
    loading: false,
    isGPRO0003: found && variant === 'variation',
    isGPRO0003Audience: found,
    error: undefined
  };
};