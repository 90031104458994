import { useCallback } from 'react';
export function useMessageReceivers(...receivers) {
  return useCallback(message => {
    for (const receiver of receivers) {
      if (message.payload.type === receiver.type) {
        receiver.onMessageReceived(message.payload);
        break;
      }
    }
  }, [receivers]);
}