import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"],
  _excluded2 = ["data"],
  _excluded3 = ["data"];
import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchAllAssignedTasks, fetchAssignedTasks, fetchEligibleUsersForTask } from '../../api/assignedTasksApi';
export const ASSIGNED_TASKS_QUERY = registerQuery({
  fieldName: 'assignedTasks',
  fetcher: () => fetchAssignedTasks()
});
export const useAssignedTasks = () => {
  var _data$assignedTasks;
  const _useQuery = useQuery(ASSIGNED_TASKS_QUERY),
    {
      data
    } = _useQuery,
    rest = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  return Object.assign({
    assignedOnboardingTasks: data === null || data === void 0 || (_data$assignedTasks = data.assignedTasks) === null || _data$assignedTasks === void 0 ? void 0 : _data$assignedTasks.assignedOnboardingTasks
  }, rest);
};
export const ALL_ASSIGNED_TASKS_QUERY = registerQuery({
  fieldName: 'allAssignedTasks',
  fetcher: () => fetchAllAssignedTasks()
});
export const useAllAssignedTasks = () => {
  var _data$allAssignedTask;
  const _useQuery2 = useQuery(ALL_ASSIGNED_TASKS_QUERY),
    {
      data
    } = _useQuery2,
    rest = _objectWithoutPropertiesLoose(_useQuery2, _excluded2);
  return Object.assign({
    allAssignedTasks: data === null || data === void 0 || (_data$allAssignedTask = data.allAssignedTasks) === null || _data$allAssignedTask === void 0 ? void 0 : _data$allAssignedTask.assignedOnboardingTasks
  }, rest);
};
export const ELIGIBLE_USERS_QUERY = registerQuery({
  fieldName: 'eligibleUsers',
  args: ['taskId'],
  fetcher: ({
    taskId
  }) => fetchEligibleUsersForTask({
    taskId
  })
});
export const useEligibleUsersForTask = variables => {
  var _data$eligibleUsers;
  const _useQuery3 = useQuery(ELIGIBLE_USERS_QUERY, {
      variables
    }),
    {
      data
    } = _useQuery3,
    rest = _objectWithoutPropertiesLoose(_useQuery3, _excluded3);
  return Object.assign({
    eligibleUsers: data === null || data === void 0 || (_data$eligibleUsers = data.eligibleUsers) === null || _data$eligibleUsers === void 0 ? void 0 : _data$eligibleUsers.eligibleUsers
  }, rest);
};