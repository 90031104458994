import { INTEGRATIONS_HIDE_SIDE_PANEL, INTEGRATIONS_SET_SELECTED_INTEGRATION, INTEGRATIONS_SHOW_SIDE_PANEL } from '../actions/ActionTypes';
const initialState = {
  isSidePanelOpen: false,
  selectedOfferingSlug: null
};
const integrations = (state = initialState, action) => {
  switch (action.type) {
    case INTEGRATIONS_SHOW_SIDE_PANEL:
      return Object.assign({}, state, {
        isSidePanelOpen: true
      });
    case INTEGRATIONS_HIDE_SIDE_PANEL:
      return Object.assign({}, state, {
        isSidePanelOpen: false
      });
    case INTEGRATIONS_SET_SELECTED_INTEGRATION:
      return Object.assign({}, state, {
        selectedOfferingSlug: action.payload.slug
      });
    default:
      return state;
  }
};
export default integrations;