import Raven from 'raven-js';
import { useMemo } from 'react';
import getLang from 'I18n/utils/getLang';
import { registerQuery, useQuery } from 'data-fetching-client';
import { HubsSettingClient } from 'frontend-preferences-client';
import { marketplaceSearchAppClient } from 'marketplace-ui-common/clients/marketplaceSearchClient';
const hubsSettingClient = HubsSettingClient.forCaller('setup-guide-api');

// Response format: [[sourceId, offeringId]]

const CHOSEN_APPS_QUERY = registerQuery({
  fieldName: 'chosenAppsData',
  fetcher() {
    return hubsSettingClient.fetch('GrowthOnboardingSetup:ChosenApps', btoa('[]')).then(value => {
      try {
        // Base 64 decode the JSON string if string is > 4 (min is "W10=" for empty array)
        const result = value.length > 4 ? JSON.parse(atob(value)) : [];
        return result;

        // TODO: remove that try/catch once we identified the cause of errors
      } catch (error) {
        console.error(error, {
          reason: value
        });
        if (error instanceof Error) {
          Raven.captureException(error, {
            extra: {
              reason: value
            }
          });
        }
        return [];
      }
    }).catch(error => {
      console.error(error);
      if (error instanceof Error) {
        Raven.captureException(error);
      }
      return [];
    });
  }
});
export function useChosenAppsData(options) {
  return useQuery(CHOSEN_APPS_QUERY, options);
}
const fetchMarketplaceApps = ({
  language = getLang(),
  queries,
  showPreferredLanguageFirst = false
}) => {
  return marketplaceSearchAppClient.post('apps/search', {
    data: {
      language,
      queries,
      showPreferredLanguageFirst
    }
  });
};
export const MARKETPLACE_APP_SEARCH = registerQuery({
  fieldName: 'marketplaceApps',
  args: ['language', 'queries', 'showPreferredLanguageFirst'],
  fetcher: fetchMarketplaceApps
});
function useMarketplaceApps(queryOptions) {
  return useQuery(MARKETPLACE_APP_SEARCH, queryOptions);
}
export function useChosenApps(options) {
  var _data$chosenAppsData;
  const {
    data
  } = useChosenAppsData(options);
  const chosenAppsData = (_data$chosenAppsData = data === null || data === void 0 ? void 0 : data.chosenAppsData) !== null && _data$chosenAppsData !== void 0 ? _data$chosenAppsData : [];
  const offeringIds = chosenAppsData.map(([, offeringId]) => offeringId);
  const {
    data: result
  } = useMarketplaceApps({
    variables: {
      queries: offeringIds.map(offeringId => ({
        offeringIds: [offeringId],
        offset: 0,
        sorts: [{
          sortField: 'RELEVANCE',
          sortOrder: 'DESC'
        }]
      }))
    },
    skip: offeringIds.length === 0
  });
  const apps = useMemo(() => {
    var _result$marketplaceAp, _result$marketplaceAp2;
    return (_result$marketplaceAp = result === null || result === void 0 || (_result$marketplaceAp2 = result.marketplaceApps) === null || _result$marketplaceAp2 === void 0 ? void 0 : _result$marketplaceAp2.results.reduce((allApps, entry) => {
      const app = entry.items[0];
      if (app) {
        allApps.push({
          id: app.id,
          name: app.name,
          offeringId: app.offeringId,
          slug: app.slug,
          iconUrl: app.iconUrl
        });
      }
      return allApps;
    }, [])) !== null && _result$marketplaceAp !== void 0 ? _result$marketplaceAp : [];
  }, [result]);
  return apps;
}