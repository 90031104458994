module.exports = {
  "gpro-0007": {
    "identifierType": "PORTAL_ID",
    "parameters": {
      "group": [
        "control",
        "variation"
      ]
    }
  }
};