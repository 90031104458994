export const APP_STARTED = 'APP_STARTED';
export const INITIALIZE_AUTH = 'INITIALIZE_AUTH';
export const ONBOARDING_SETTINGS_ERROR = 'ONBOARDING_SETTINGS_ERROR';
export const ONBOARDING_SETTINGS_RECEIVED = 'ONBOARDING_SETTINGS_RECEIVED';
export const ONBOARDING_VIEW_ACTIVATED = 'ONBOARDING_VIEW_ACTIVATED';
export const EXPERIMENTS_REQUESTING = 'EXPERIMENTS_REQUESTING';
export const EXPERIMENTS_SUCCESSFUL = 'EXPERIMENTS_SUCCESSFUL';
export const EXPERIMENTS_ERROR = 'EXPERIMENTS_ERROR';
export const INTEGRATIONS_SHOW_SIDE_PANEL = 'INTEGRATIONS_SHOW_SIDE_PANEL';
export const INTEGRATIONS_HIDE_SIDE_PANEL = 'INTEGRATIONS_HIDE_SIDE_PANEL';
export const INTEGRATIONS_SET_SELECTED_INTEGRATION = 'INTEGRATIONS_SET_SELECTED_INTEGRATION';