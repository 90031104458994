import portalIdParser from 'PortalIdParser';
import { createClient } from 'laboratory-lib';
import experiments from '../experiments.yaml';
import { TREATMENTS_SCHEMA } from '../constants/experiments';
export const laboratoryClient = createClient({
  identifiers: {
    portalId: portalIdParser.get()
  },
  experiments: experiments
});
export const logExposure = experiment => laboratoryClient.logExposure(experiment);
export const isGroupTypeValue = (value, experimentKey) => {
  const treatment = TREATMENTS_SCHEMA[experimentKey];
  return Object.values(treatment.parameters.group).includes(value);
};
export const getExperimentVariant = (experimentKey, treatments) => {
  var _treatments$experimen;
  const group = treatments === null || treatments === void 0 || (_treatments$experimen = treatments[experimentKey]) === null || _treatments$experimen === void 0 || (_treatments$experimen = _treatments$experimen.parameters) === null || _treatments$experimen === void 0 ? void 0 : _treatments$experimen.group;
  if (isGroupTypeValue(group, experimentKey)) {
    return group;
  }
  return undefined;
};