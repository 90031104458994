export let Microapp;
(function (Microapp) {
  Microapp["CampaignAssistant"] = "campaign-assistant";
  Microapp["GuideCreator"] = "guide-creator";
  Microapp["ClipCreator"] = "clip-creator";
  Microapp["EmailSignatureGenerator"] = "email-signature-generator";
  Microapp["BrandKitGenerator"] = "brand-kit-generator";
  Microapp["BlogIdeasGenerator"] = "blog-ideas-generator";
  Microapp["LandingPageCreator"] = "landing-page-creator";
  Microapp["WebsiteGrader"] = "website-grader";
  Microapp["InvoiceGenerator"] = "invoice-generator";
})(Microapp || (Microapp = {}));